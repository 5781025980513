<div class="error-container" *ngIf="errorObservable | async as error">
    <div class="error-content-container">
        <div class="error-content-text">
            <span class="fs-3xl">{{error.code}}</span>
            <span class="fs-xxl fg-gray">{{error.title}}</span>
            <span class="fs-lg fg-gray">{{error.message}}</span>
        </div>
        <div class="error-content-button">
            <button mat-raised-button color="primary" (click)='navigateBack()'>Zurück</button>
        </div>
    </div>
</div>