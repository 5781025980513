/**
 * Animations for the chevron icon
 */
import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

export const chevronAnimation = [
    trigger('rotateChevron', [
        state('initial', style({
            transform: 'rotate(0)'
        })),
        state('final', style({
            transform: 'rotate(180deg)'
        })),
        transition('initial=>final', animate('200ms ease-in-out')),
        transition('final=>initial', animate('200ms ease-in-out'))
    ]),
];
