import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ServiceError } from '@core/models/service-error';
import { Observable } from 'rxjs';

import { ErrorService } from '../../services/error.service';

@Component({
    selector: 'k5-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {

    constructor(private errorService: ErrorService, private location: Location) { }

    /**
     * Returniert das Error-Observable
     */
    get errorObservable(): Observable<ServiceError> {
        return this.errorService.error$;
    }

    navigateBack() {
        this.location.back();
    }

}
