import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { InitializationComponent } from '@core/components/initialization/initialization.component';
import { DialogActionsComponent, DialogContentComponent, DialogHeadingComponent } from '@k5next/ui-components';
import { NotificationDatePipe } from '@shared/pipes/notification-date.pipe';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { AlertComponent } from './components/alert/alert.component';
import { BlankComponent } from './components/blank/blank.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { InfoWindowComponent } from './components/info-window/info-window.component';
import { NavContentComponent } from './components/nav-content/nav-content.component';
import { NavLinkComponent } from './components/nav-link/nav-link.component';
import { NotificationAlertComponent } from './components/notification-alert/notification-alert.component';
import { ProblemAlertComponent } from './components/problem-alert/problem-alert.component';
import { SimpleAlertComponent } from './components/simple-alert/simple-alert.component';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { AuthGuard } from './guards/auth.guard';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { A11yModule } from '@angular/cdk/a11y';

/**
 * Zentrales Modul für Grundlegende Services und Funktionen
 */
@NgModule({
    declarations: [
        AlertComponent,
        InitializationComponent,
        ErrorPageComponent,
        NavContentComponent,
        NavLinkComponent,
        InfoWindowComponent,
        SimpleAlertComponent,
        ProblemAlertComponent,
        UnsavedChangesDialogComponent,
        BlankComponent,
        NotificationAlertComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot([]),
        MaterialModule,
        // TODO: Das Core-Modul darf keine Abhängigkeiten zu sonstigen Modulen haben und muss eigenständig agieren. SharedModul gehört als Abhängigkeit entfert.
        SharedModule,
        ReactiveFormsModule,
        DialogHeadingComponent,
        DialogContentComponent,
        DialogActionsComponent,
        NotificationsComponent,
        A11yModule
    ],
    exports: [NavContentComponent, InitializationComponent, ErrorPageComponent, InfoWindowComponent],
    providers: [AuthGuard, NotificationDatePipe]
})
export class CoreModule {
    // prevents multiple imports of core module
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
