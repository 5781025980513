// @ts-strict-ignore
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';

/**
 * Komponente für die Darstellung eines Navigationslinks
 */
@Component({
    selector: 'k5-nav-link',
    templateUrl: './nav-link.component.html',
    styleUrls: ['./nav-link.component.scss']
})
export class NavLinkComponent implements OnChanges {
    /**
     * Titel des Links
     */
    @Input()
    label = '<!-- please specify a label -->';

    /**
     * Link zum routerLink
     * Wenn die Navigationsurl gesetzt ist, wird der Link keine mehr Auswirkungen haben siehe 'linkClicked' Methode
     */
    @Input()
    link = '#';

    /**
     * Navigationsurl
     * Wenn die Navigationsurl gesetzt ist, wird der Link keine mehr Auswirkungen haben siehe 'linkClicked' Methode
     */
    @Input()
    url: string | null = null;

    /**
     * FontAwesome Icon
     */
    @Input()
    icon = 'fa-axe-battle';

    /**
     * Legt fest, ob ein Indikator für Kinderlinks angezeigt wird
     */
    @Input()
    children = false;

    /**
     * Legt fest, ob die Subnavigationslinks angezeigt werden
     */
    @Input()
    childrenVisible = false;

    /**
     * Legt fest, ob der Menüpunkt sichtbar ist
     */
    @Input()
    visible: boolean = true;

    /**
     * Ebene der Darstellung
     */
    @Input()
    navLevel: number = 1;

    /**
     * Schmale Anzeige
     */
    @Input()
    collapsed = false;

    /**
     * Sichtbarkeit der Schnellaktion
     */
    @Input()
    actionEnabled = false;

    /**
     * FontAwesome-Icon für die Schnellaktion
     */
    @Input()
    actionIcon = 'axe-battle';

    /**
     * Tooltip für die Aktion
     */
    @Input()
    actionTooltip: string;

    /**
     * Tooltip offset für scrolling im mittleren Bereich
     */
    @Input()
    tooltipOffsetTop = 0;

    /**
     * Anzahl der Benachrichtigungen
     */
    @Input()
    badgeCount = 0;

    /**
     * Profilbild des Users
     */
    @Input()
    picture: string = null;

    /**
     * Menüeintrag wird bei exakter Übereinstimmung der URL selektiert
     */
    @Input()
    exactUrlActiveMatching: boolean;

    /**
     * Ereignisemitter für Navigationsklicks
     */
    @Output()
    navlinkClick: EventEmitter<Event> = new EventEmitter<Event>();

    /**
     * Ereignisemitter für Klicks auf eine Schnellaktion
     */
    @Output()
    actionClick: EventEmitter<Event> = new EventEmitter<Event>();

    @ViewChild('linkElement', { static: true }) linkElement: ElementRef;
    @ViewChild('tooltipElement', { static: true }) tooltipElement: ElementRef;

    constructor(private renderer: Renderer2) {}

    /**
     * Korrigiert das vertikale offset der Overlays
     */
    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.hasOwnProperty('tooltipOffsetTop') && this.tooltipElement?.nativeElement) {
            if (this.tooltipOffsetTop === 0) {
                this.renderer.removeStyle(this.tooltipElement.nativeElement, 'top');
            } else {
                this.renderer.setStyle(
                    this.tooltipElement.nativeElement,
                    'top',
                    `${this.linkElement.nativeElement.offsetTop - this.tooltipOffsetTop}px`
                );
            }
        }
    }

    /**
     * Behandelt Klicks auf den Navigationslink
     * Wenn die Url gesetzt ist hat  der Link keine mehr Auswirkung
     * @param event ClickEvent
     */
    linkClicked(event: Event) {
        // Wenn Url vorhanden ist, wird geöffnet
        if (this.url) {
            window.open(this.url, '_blank');
        } else {
            this.navlinkClick.emit(event);
        }
    }

    /**
     * Aktionsereignis
     * @param event ClickEvent
     */
    actionClicked(event: Event) {
        this.actionClick.emit(event);
    }
}
