import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

/**
 * Zentraler Interceptor bei HTTP-Aufrufen
 */
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    constructor(private authService: OAuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Authentifizierungs-Token anhängen bei jedem Http-Request
        req = this.addAuthToken(req);

        // Ausführen des Request
        return next.handle(req);
    }

    /**
     * Http-Request um Authentifizierungs-Token erweitern
     * @param req: HttpRequest
     */
    private addAuthToken(req: HttpRequest<any>) {
        if (this.authService.hasValidAccessToken()) {
            const userAuthToken = this.authService.getAccessToken();
            if (userAuthToken) {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${userAuthToken}`
                    }
                });
            }
        }
        return req;
    }
}
