import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UiConstants } from '@core/constants/ui-constants';
import { UserInformationService } from '@core/services/user-information.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

/**
 * Zentraler Interceptor bei HTTP-Aufrufen
 * um die MandantId in den Request Header einzufügen
 * Der Request Header kann bereits eine MandantId enthalten,
 * z.B.: bei einem Mandantenwechsel. In diesem Fall soll der
 * Header nicht verändert werden.
 */
@Injectable()
export class HttpMandantIdInterceptor implements HttpInterceptor {
    constructor(
        private authService: OAuthService,
        private userService: UserInformationService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Prüfen ob bereits eine Header mit der MandantId hinzugefügt wurde
        // Das ist der Fall wenn z.B.: ein Mandantenwechsel durchgeführt wird
        if (!req.headers.has(UiConstants.MANDANT_ID_HEADER)) {
            // MandantId zum Http-Request hinzufügen
            req = this.addMandantId(req);
        }

        // Ausführen des Request
        return next.handle(req);
    }

    /**
     * Http-Request um MandantId-Header erweitern
     * @param req: HttpRequest
     */
    private addMandantId(req: HttpRequest<any>) {
        if (this.authService.hasValidAccessToken()) {
            const mandantId = this.userService.getMandantId();
            if (mandantId !== '') {
                req = req.clone({
                    setHeaders: {
                        [UiConstants.MANDANT_ID_HEADER]: `${mandantId}`
                    }
                });
            }
        }

        return req;
    }
}
