// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Environment Konfiguration
 *
 * Für das Deployment auf unterschiedliche Stages wurde die Konfiguration auf eine externe Konfigurationsdatei
 * ausgelagert. Diese befindet sich im '../assets/config'-Ordner der Anwendung und wird über das
 * ConfigAssetLoader-Service beim Start der Anwendung ausgelesen.
 */

// Hier darf nur production gesetzt werden, sonst keine Variablen
export const environment = {
    production: false,
    // Refresh time von 3h um Last zu vermeiden
    notificationRefreshTime: 10800000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
