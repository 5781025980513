import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Event } from '@angular/router';

@Component({
    selector: 'k5-info-window',
    templateUrl: './info-window.component.html',
    styleUrls: ['./info-window.component.scss']
})
export class InfoWindowComponent {

    @Output()
    primaryButtonAction: EventEmitter<Event> = new EventEmitter<Event>();

    @Output()
    secondaryButtonAction: EventEmitter<Event> = new EventEmitter<Event>();

    @Input()
    primaryButtonName = null;

    @Input()
    secondaryButtonName = null;

    @Input()
    icon = null;

    @Input()
    iconColor = 'gray';

    @Input()
    title = null;

    @Input()
    description = null;

    @Input()
    isLoading = false;

    secondaryButtonClick(event: any): void {
        this.secondaryButtonAction.emit(event);
    }

    primaryButtonClick(event: any): void {
        this.primaryButtonAction.emit(event);
    }

}
