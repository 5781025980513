// @ts-strict-ignore
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { UserInformationService } from '@core/services/user-information.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

/**
 * Zentraler Interceptor bei HTTP-Aufrufen
 */
@Injectable()
export class HttpHeaderDatenInterceptor implements HttpInterceptor {
    benutzerInfo$ = this.userService.benutzerInfo$;

    constructor(
        private authService: OAuthService,
        private userService: UserInformationService,
        private configService: ConfigAssetLoaderService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const personenUiApiUrl = this.configService.getConfig().k5civisperson.personenUiApiUrl;
        const personenApiUrl = this.configService.getConfig().k5civisperson.personenApiUrl;
        const adressenUiApiUrl = this.configService.getConfig().k5civisAdr.adressenUiApiUrl;
        const adressenApiUrl = this.configService.getConfig().k5civisAdr.adressenApiUrl;

        // Prüfen, ob es sich um einen Aufruf des Personenservice handelt
        if (req.url.startsWith(personenUiApiUrl) || req.url.startsWith(personenApiUrl)) {
            // Headerdaten zum Http-Request hinzufügen
            req = this.addPersonenHeaderDaten(req);
        }
        // Prüfen, ob es sich um einen Aufruf des Adressenservice handelt
        if (req.url.startsWith(adressenUiApiUrl) || req.url.startsWith(adressenApiUrl)) {
            // Headerdaten zum Http-Request hinzufügen
            req = this.addAdressenHeaderDaten(req);
        }

        // Ausführen des Requests
        return next.handle(req);
    }

    /**
     * Http-Request um Headerdaten für Personenservice erweitern
     * @param req: HttpRequest
     */
    private addPersonenHeaderDaten(req: HttpRequest<any>): HttpRequest<any> {
        if (this.authService.hasValidAccessToken()) {
            this.benutzerInfo$.subscribe((benutzer) => {
                if (benutzer) {
                    req = req.clone({
                        setHeaders: {
                            'k5-Version': 'latest',
                            'k5-Benutzername': this.normalizeString(benutzer?.vorname + ' ' + benutzer?.familienname),
                            'k5-Benutzerkennung': benutzer?.email,
                            'k5-Anwendungsname': 'k5Next',
                            'k5-Anwendungsversion': '1'
                        }
                    });
                }
            });
        }
        return req;
    }

    /**
     * Fügt k5-Anwendungsname zu den Request-Headers hinzu.
     * @param req zu dem Headers hinzugefügt wird.
     */
    private addAdressenHeaderDaten(req: HttpRequest<any>): HttpRequest<any> {
        if (this.authService.hasValidAccessToken()) {
            this.benutzerInfo$.subscribe((benutzer) => {
                if (benutzer) {
                    req = req.clone({
                        setHeaders: {
                            'k5-Anwendungsname': 'k5Next'
                        }
                    });
                }
            });
        }
        return req;
    }

    /**
     * Normalisiert den String, um Sonderzeichen zu entfernen.
     * @param str String zu normalisieren.
     * @returns Normalisierter String.
     */
    normalizeString(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
}
