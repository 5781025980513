import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ServiceError } from '@core/models/service-error';
import { Router } from '@angular/router';
import { PathConstants } from '@core/constants/url-constants';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    private error: BehaviorSubject<ServiceError> = new BehaviorSubject<ServiceError>({
        code: '404',
        title: 'Seite nicht gefunden 😢',
        message: 'Versuchen Sie es später erneut',
        reason: ''
    });

    error$ = this.error.asObservable();

    constructor(private router: Router) {
    }

    /**
     * Meldet einen neuen kritischen Fehler
     * @param error Auftretender Fehler
     */
    reportError(error: ServiceError) {
        this.error.next(error);
        this.router.navigate([PathConstants.ERROR]);
    }

}
