import { Injectable } from '@angular/core';
import { LoadingInfo } from '@core/models/loading-info';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalLoadingService {

    private loading: BehaviorSubject<LoadingInfo> = new BehaviorSubject<LoadingInfo>({
        loading: false,
        message: ''
    });

    loading$ = this.loading.asObservable();

    /**
     * Startet die Anzeige des globalen Ladeindikators
     * @param message Nachricht, die beim Ladeindikator angezeigt wird
     */
    startLoading(message: string) {
        this.loading.next({
            loading: true,
            message
        });
    }

    /**
     * Beendet die Anzeige des globalen Ladeindikators
     */
    endLoading(): void {
        this.loading.next({
            loading: false,
            message: ''
        });
    }
}
