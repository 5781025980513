<div class="info-container">
    <div class="info-card">
        <div class="info-header"></div>

        <!-- Information text -->
        <div class="info-content px-6 pb-6">
            <div class="info-title pt-6">
                <i
                    *ngIf="icon"
                    class="text-xl"
                    [ngClass]="'fa-duotone fa-' + icon + ' fg-' + iconColor"
                    aria-hidden="true"
                ></i>
                <span class="text-xl font-bold text-gray-500">{{ title }}</span>
            </div>
            <div class="pt-4" *ngIf="!isLoading">
                {{ description }}
            </div>
            <div *ngIf="isLoading" class="info-content-spinner py-6">
                <mat-spinner [diameter]="140"></mat-spinner>
                <span>{{ description }}</span>
            </div>

            <div class="grow" *ngIf="secondaryButtonName || primaryButtonName"></div>

            <div class="info-action-buttons pb-6" *ngIf="secondaryButtonName || primaryButtonName">
                <button
                    mat-stroked-button
                    color="primary"
                    class="w-full"
                    (click)="secondaryButtonClick($event)"
                    *ngIf="secondaryButtonName"
                >
                    {{ secondaryButtonName }}
                </button>
                <button
                    mat-raised-button
                    color="primary"
                    class="w-full"
                    (click)="primaryButtonClick($event)"
                    *ngIf="primaryButtonName"
                >
                    {{ primaryButtonName }}
                </button>
            </div>
            <div class="pb-2 text-xs">k5|Next - Entwicklungspartnerschaft:</div>
            <div class="identity-parnership-container">
                <img alt="gemdatNOE" class="identity-parnership-logo" src="assets/partnershiplogos/gemdatNOE.svg" />
                <img alt="gemdadOOE" class="identity-parnership-logo" src="assets/partnershiplogos/gemdadOOE.svg" />
                <img alt="kufgem" class="identity-parnership-logo" src="assets/partnershiplogos/kufgem.svg" />
                <img alt="psc" class="identity-parnership-logo" src="assets/partnershiplogos/psc.svg" />
                <img
                    alt="gemeindeverbandVBG"
                    class="identity-parnership-logo"
                    src="assets/partnershiplogos/gemeindeverbandVBG.svg"
                />
            </div>
        </div>
    </div>
</div>
