<!-- Gemeindewappen -->
<div
    [ngClass]="{
        'nav-branding-sidenavSmall': navigationService.sidenavSmall,
        'nav-branding': !navigationService.sidenavSmall
    }"
>
    <mat-select
        [formControl]="mandant"
        required
        *ngIf="mandantwechselItems$ | async as mandantenWechselItems"
        (selectionChange)="changeCurrentMandantIfNotEditingOrUserAcceptsDialog()"
        matInput
        class="mandant-select"
        data-cy="mandant-select"
        [panelWidth]="300"
    >
        <mat-select-trigger>
            <k5-mandant-darstellung
                [bildUrl]="mandant.value?.bildUriPath | mandantServiceUrl"
                [darstellungData]="mandant.value?.darstellung"
                [displaySmall]="navigationService.sidenavSmall"
            >
            </k5-mandant-darstellung>
        </mat-select-trigger>
        <mat-option
            class="h-16"
            *ngFor="let mandantElement of mandantenWechselItems"
            [value]="mandantElement"
            data-cy="mandant-option"
        >
            <div class="w-64">
                <k5-mandant-darstellung
                    [bildUrl]="mandantElement.bildUriPath | mandantServiceUrl"
                    [darstellungData]="mandantElement.darstellung"
                >
                </k5-mandant-darstellung>
            </div>
        </mat-option>
    </mat-select>
</div>

<!-- Fixierte Links oben -->
<div *ngIf="isAuthenticated()">
    <div *ngFor="let navItem of navItemsStart$ | async">
        <div class="line-separator large-line-separator"></div>
        <k5-nav-link
            [label]="navItem.label"
            [link]="navItem.routerLink"
            [children]="navItem.hasChildren"
            [childrenVisible]="navItem.childrenVisible"
            [icon]="navItem.icon"
            [navLevel]="navItem.navLevel"
            [exactUrlActiveMatching]="navItem.exactUrlActiveMatching"
            [collapsed]="navigationService.sidenavSmall$ | async"
            [attr.data-cy]="navItem.cypressId"
        >
        </k5-nav-link>
    </div>
</div>

<!--K5Next Apps-->
<div class="nav-items-container" *ngIf="isAuthenticated(); else placeholder" (scroll)="scrolled($event)">
    <div *ngFor="let navItem of navItemsCenter$ | async">
        <ng-container *ngIf="hasPermissions(navItem.permissions)">
            <div *ngIf="isNavItemVisible(navItem.label)" class="line-separator large-line-separator"></div>
            <k5-nav-link
                [label]="navItem.label"
                [link]="navItem.routerLink"
                [url]="navItem.url"
                [children]="navItem.hasChildren"
                [childrenVisible]="navItem.childrenVisible"
                [visible]="isNavItemVisible(navItem.label)"
                [icon]="navItem.icon"
                [navLevel]="navItem.navLevel"
                [exactUrlActiveMatching]="navItem.exactUrlActiveMatching"
                [collapsed]="navigationService.sidenavSmall$ | async"
                [tooltipOffsetTop]="scrollTop"
                [attr.data-cy]="navItem.cypressId"
            >
            </k5-nav-link>
            <div *ngFor="let nav2ndLevel of navItem.children">
                <div *ngIf="hasPermissions(nav2ndLevel.permissions) && navItem.childrenVisible">
                    <div class="line-separator small-line-separator"></div>
                    <k5-nav-link
                        [label]="nav2ndLevel.label"
                        [link]="nav2ndLevel.routerLink"
                        [children]="nav2ndLevel.hasChildren"
                        [childrenVisible]="nav2ndLevel.childrenVisible"
                        [icon]="nav2ndLevel.icon"
                        [navLevel]="nav2ndLevel.navLevel"
                        [exactUrlActiveMatching]="nav2ndLevel.exactUrlActiveMatching"
                        [collapsed]="navigationService.sidenavSmall$ | async"
                        [tooltipOffsetTop]="scrollTop"
                        [attr.data-cy]="nav2ndLevel.cypressId"
                    >
                    </k5-nav-link>
                </div>
                <div *ngFor="let nav3rdLevel of nav2ndLevel.children">
                    <div *ngIf="hasPermissions(nav3rdLevel.permissions) && nav2ndLevel.childrenVisible">
                        <div class="line-separator small-line-separator"></div>
                        <k5-nav-link
                            [label]="nav3rdLevel.label"
                            [link]="nav3rdLevel.routerLink"
                            [children]="nav3rdLevel.hasChildren"
                            [icon]="nav3rdLevel.icon"
                            [navLevel]="nav3rdLevel.navLevel"
                            [exactUrlActiveMatching]="nav3rdLevel.exactUrlActiveMatching"
                            [collapsed]="navigationService.sidenavSmall$ | async"
                            [tooltipOffsetTop]="scrollTop"
                            [attr.data-cy]="nav3rdLevel.cypressId"
                        >
                        </k5-nav-link>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <!--Menüitems die darunter gereiht werden müssen-->
    <div *ngFor="let navItemEnd of navItemsEnd$ | async">
        <ng-container *ngIf="hasPermissions(navItemEnd.permissions)">
            <div *ngIf="isNavItemVisible(navItemEnd.label)" class="line-separator large-line-separator"></div>
            <k5-nav-link
                [label]="navItemEnd.label"
                [link]="navItemEnd.routerLink"
                [url]="navItemEnd.url"
                [children]="navItemEnd.hasChildren"
                [childrenVisible]="navItemEnd.childrenVisible"
                [visible]="isNavItemVisible(navItemEnd.label)"
                [icon]="navItemEnd.icon"
                [navLevel]="navItemEnd.navLevel"
                [exactUrlActiveMatching]="navItemEnd.exactUrlActiveMatching"
                [collapsed]="navigationService.sidenavSmall$ | async"
                [tooltipOffsetTop]="scrollTop"
                [attr.data-cy]="navItemEnd.cypressId"
            >
            </k5-nav-link>
            <div *ngFor="let nav2ndLevel of navItemEnd.children">
                <div *ngIf="hasPermissions(nav2ndLevel.permissions) && navItemEnd.childrenVisible">
                    <div class="line-separator small-line-separator"></div>
                    <k5-nav-link
                        [label]="nav2ndLevel.label"
                        [link]="nav2ndLevel.routerLink"
                        [children]="nav2ndLevel.hasChildren"
                        [childrenVisible]="nav2ndLevel.childrenVisible"
                        [icon]="nav2ndLevel.icon"
                        [navLevel]="nav2ndLevel.navLevel"
                        [exactUrlActiveMatching]="nav2ndLevel.exactUrlActiveMatching"
                        [collapsed]="navigationService.sidenavSmall$ | async"
                        [tooltipOffsetTop]="scrollTop"
                        [attr.data-cy]="nav2ndLevel.cypressId"
                    >
                    </k5-nav-link>
                </div>
                <div *ngFor="let nav3rdLevel of nav2ndLevel.children">
                    <div *ngIf="hasPermissions(nav3rdLevel.permissions) && nav2ndLevel.childrenVisible">
                        <div class="line-separator small-line-separator"></div>
                        <k5-nav-link
                            [label]="nav3rdLevel.label"
                            [link]="nav3rdLevel.routerLink"
                            [children]="nav3rdLevel.hasChildren"
                            [icon]="nav3rdLevel.icon"
                            [navLevel]="nav3rdLevel.navLevel"
                            [exactUrlActiveMatching]="nav3rdLevel.exactUrlActiveMatching"
                            [collapsed]="navigationService.sidenavSmall$ | async"
                            [tooltipOffsetTop]="scrollTop"
                            [attr.data-cy]="nav3rdLevel.cypressId"
                        >
                        </k5-nav-link>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="line-separator large-line-separator"></div>
</div>

<ng-template #placeholder>
    <h1>Nicht authentifiziert</h1>
</ng-template>

<!-- Fixierte Links unten -->
<div class="footer" *ngIf="isAuthenticated()">
    <div class="line-separator large-line-separator"></div>
    <k5-nav-link
        label="Hilfe"
        tabindex="0"
        class="cursor-pointer"
        (navlinkClick)="hilfeNavLinkClicked()"
        (keydown.enter)="hilfeNavLinkClicked()"
        [link]="null"
        icon="fa-duotone fa-location-question"
        [navLevel]="1"
        [collapsed]="navigationService.sidenavSmall$ | async"
        data-cy="nav-content-k5-nav-link-k5next-hilfe"
    >
    </k5-nav-link>
    <div class="line-separator large-line-separator"></div>
    <k5-nav-link
        label="Benachrichtigungen"
        tabindex="0"
        class="cursor-pointer"
        (navlinkClick)="toggleNotificationPanel()"
        (keydown.enter)="toggleNotificationPanel()"
        [link]="null"
        cdkOverlayOrigin
        #notificationOverlayTrigger="cdkOverlayOrigin"
        icon="bell"
        [navLevel]="1"
        [collapsed]="navigationService.sidenavSmall$ | async"
        [badgeCount]="badgeCount"
        data-cy="nav-content-k5-nav-link-benachrichtigungen"
    >
    </k5-nav-link>
    <div class="line-separator large-line-separator"></div>
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="notificationOverlayTrigger"
        [cdkConnectedOverlayOpen]="notificationPanelOpen"
        [cdkConnectedOverlayHasBackdrop]="notificationPanelOpen"
        [cdkConnectedOverlayOffsetX]="(navigationService.sidenavSmall$ | async) ? widthSidenavSmall : widthSidenav"
        [cdkConnectedOverlayOffsetY]="heightMenuEntry"
        (backdropClick)="notificationPanelOpen = !notificationPanelOpen"
    >
        <k5-notifications
            class="content-end"
            cdkTrapFocus
            [cdkTrapFocusAutoCapture]="true"
            [ngClass]="(navigationService.sidenavSmall$ | async) ? 'notification-panel-small' : 'notification-panel'"
            (closePanel)="toggleNotificationPanel()"
        >
        </k5-notifications>
    </ng-template>

    <k5-nav-link
        [label]="userName"
        [link]="routerConstants.USERPROFILE"
        icon="user-circle"
        [navLevel]="1"
        [collapsed]="navigationService.sidenavSmall$ | async"
        [exactUrlActiveMatching]="false"
        [actionEnabled]="true"
        actionIcon="sign-out"
        [picture]="getPicture()"
        actionTooltip="Abmelden"
        (actionClick)="logout()"
        data-cy="nav-content-k5-nav-link-user"
    >
    </k5-nav-link>
</div>
