// @ts-strict-ignore
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@core/services/alert.service';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { UserInformationService } from '@core/services/user-information.service';
import {
    K5NextNotification,
    K5NextNotificationAction,
    K5NextNotificationInfo,
    NotificationPanelComponent
} from '@k5next/ui-components';
import { NotificationConstants } from '@shared/constants/shared-constants';
import { NotificationService } from '@shared/services/notification.service';

@Component({
    selector: 'k5-notifications',
    standalone: true,
    imports: [CommonModule, NotificationPanelComponent],
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.components.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent {
    dataSourceBaseUrl = signal('');
    @Output()
    closePanel: EventEmitter<void> = new EventEmitter();

    // Services injekten
    userInformationService = inject(UserInformationService);
    alertService = inject(AlertService);
    notificationService = inject(NotificationService);
    configAssetLoaderService = inject(ConfigAssetLoaderService);
    router = inject(Router);

    constructor() {
        // Abfrage Base Url von Mandantenservice besorgen
        this.dataSourceBaseUrl.set(this.configAssetLoaderService.getConfig().k5Mandanten.mandantenServiceBaseUrl);
    }

    /**
     * Handelt den Error Event von Notification Panel.
     * @param error Übermittelte HttpError.
     */
    handleError(error: HttpErrorResponse): void {
        this.alertService.errorResponse(error, 'Fehler während der Verarbeitung der Benachrichtigungen');
    }

    /**
     * Handelt den Click Event auf eine Notification.
     * @param notification auf die geklickt wurde.
     */
    handleClick(notification: K5NextNotification): void {
        if (notification.typ === NotificationConstants.TYP_K5NEXT_VERWEIS) {
            const url = this.notificationService.getVerweisUrlNeu(notification);
            this.router.navigateByUrl(url);
            this.closePanel.emit();
        } else if (notification.typ === NotificationConstants.TYP_LINK) {
            window.open(notification.daten['url'], '_blank')?.focus();
        }
    }

    /**
     * Setzt die Anzahl der ungelesene Benachrichtigungen für die UI Darstellung.
     * @param numberOfUnreadNotifications die Anzahl der ungelesene Beanchrichtigungen.
     */
    handleNumberOfUnreadNotifications(numberOfUnreadNotifications: number): void {
        this.notificationService.setNotificationAmount(numberOfUnreadNotifications);
    }

    /**
     * Handelt das Notification Info Event.
     * @param event, das übermittelte Info Event.
     */
    handleNotificationInfoEvent(event: K5NextNotificationInfo): void {
        this.notificationService.setNotificationAmount(event.numberOfUnreadNotifications);
        if (event.action === K5NextNotificationAction.Click) {
            if (event.notification) {
                this.handleClick(event.notification);
            }
        }
    }
}
